import getEnv from "@/utils/env";

const BASEPATH = {
  rail: getEnv("VUE_APP_RAIL_URL"),
  railGate: getEnv("VUE_APP_RAIL_GATE_URL"),
  yard: getEnv("VUE_APP_YARD_URL"),
  report: getEnv("VUE_APP_REPORT_URL"),
  rail_socket: getEnv("VUE_APP_RAIL_SOCKET_URL"),
  authentication: getEnv("VUE_APP_AUTHENTICATION_URL"),
  registry: getEnv("VUE_APP_REGISTRY_URL"),
  audit: getEnv("VUE_APP_AUDIT_URL"),
  block: getEnv("VUE_APP_BLOCKS_URL"),
  gateway: getEnv("VUE_APP_AUDIT_URL").replace("audit","gateway"),
  OFFLINE: "/assets/offlineData/",
  root: getEnv("VUE_APP_BASE_URL"),
};

const BASE_PATH = BASEPATH.rail;
const BASE_PATH_YARD = BASEPATH.yard;
const BASE_PATH_RAIL = BASEPATH.rail;
const BASE_PATH_RAIL_GATE = BASEPATH.railGate;
const BASE_PATH_REPORT = BASEPATH.report;
const RAIL_SOCKET_URL = BASEPATH.rail_socket;
const BASE_PATH_AUTHENTICATION = BASEPATH.authentication;
const BASE_PATH_REGISTRY = BASEPATH.registry;
const BASE_PATH_AUDIT = BASEPATH.audit;
const BASE_PATH_ROOT = BASEPATH.root;
const BASE_PATH_BLOCK = BASEPATH.block;
const BASE_PATH_GATEWAY = BASEPATH.gateway;

console.log("BASE PATH", BASEPATH);
console.log("ENV", process.env);
//console.log("window?.configs", window ? .configs);

export default {
  BASEPATH,
  BASE_PATH,
  BASE_PATH_RAIL,
  BASE_PATH_RAIL_GATE,
  RAIL_SOCKET_URL,
  BASE_PATH_YARD,
  BASE_PATH_AUTHENTICATION,
  BASE_PATH_AUDIT,
  BASE_PATH_REGISTRY,
  BASE_PATH_REPORT,
  RTBTipoCarro: `${BASE_PATH}/tipoCarri`,
  RTTTraccia: `${BASE_PATH}/tracce`,
  BASE_PATH_ROOT,
  BASE_PATH_BLOCK,
  BASE_PATH_GATEWAY,
};
